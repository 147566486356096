<template>
    <div class="p-grid crud-demo">
        <Loader v-model="loading" />
        <HelperDialog v-model="customerHelper" header="Buscar Cliente" :headers="customersHeaders" :rows="customers"
            @selected="selectCustomer" />
        <div class="col-12">
            <div class="card">
                <div class="p-fluid formgrid grid">
                    <div class="col-12">
                        <BasicFormToolbar :actions="['new', 'save', 'list']" @new="openNewpayment" @save="savepayment" @list="openTable">
                        </BasicFormToolbar>
                    </div>
                </div>
                <Fieldset legend="Cliente">
                    <div class="p-fluid formgrid grid">
                        <FormInputText wrapperClass = "'field col-12 md:col-6 xl:col-6'" label="Nombre" v-model="entity.customer_name"
                        :valid="validate.validations.customer_name" @search="(customerHelper.visible = true)" :search="true" :readonly="true"/>
                        <FormInputText wrapperClass = "'field col-12 md:col-6 xl:col-6'" label="RFC" v-model="entity.customer_rfc"
                        :valid="validate.validations.customer_rfc"/>
                    </div>
                </Fieldset>
                <br>
                <Fieldset legend="Pago" :collapsed="false" :toggleable="true">
                    <div class="p-fluid formgrid grid">
                        <FormCalendar wrapperClass = "field col-12 md:col-3 xl:col-3" dateFormat="dd/mm/yy" v-model="entity.date" :showIcon="true" label="Fecha"/>
                        <FormDropdownComplex wrapperClass="field col-12 md:col-3 xl:col-3"  v-model="entity.payment_type" :options="c_FormaPago" optionLabel="Descripcion" optionValue="c_FormaPago" :label="'Tipo Pago'" 
                        :labelInOption="'c_FormaPago - Descripcion'" :labelInValue="'c_FormaPago - Descripcion'" :filter="true" tooltip="Se obtiene del catalogo del SAT: Forma de Pago"/>
                        <FormDropdownComplex wrapperClass="field col-12 md:col-3 xl:col-3"  v-model="entity.id_bank_account_destination" :options="bank_accounts" optionLabel="name" optionValue="id" :label="'Cuenta Bancaria'" 
                        :labelInOption="'id - name'" :labelInValue="'id - name'" :filter="true" tooltip="Dar de alta en esta ruta: Catalogos/CXP y Bancos/Cuenta Bancaria"/>
                        <FormDropdownComplex wrapperClass="field col-12 md:col-3 xl:col-3"  v-model="entity.currency" :options="c_Moneda" optionLabel="Descripcion" optionValue="c_Moneda" :label="'Moneda'" 
                        :labelInOption="'c_Moneda - Descripcion'" :labelInValue="'c_Moneda - Descripcion'" :filter="true" tooltip="Se obtiene del catalogo del SAT: Moneda"/>
                        <FormInputNumber label="Tasa de Cambio" v-model="entity.exchange_rate" mode="currency" :disabled="entity.currency == 'MXN'" 
                        wrapperClass="field col-12 md:col-3 xl:col-3"/>
                        <FormInputNumber wrapperClass="field col-12 md:col-3 xl:col-3" label="Importe" v-model="entity.amount" mode="currency"/>
                    </div>
                </Fieldset>
                <br>
                <Fieldset :toggleable="true" legend="Depositos">
                    <div class="p-fluid formgrid grid">
                        <div class="field col-12 md:col-12 xl:col-12">
                            <BasicDatatable @selected="selectItem" :headers="Hpayments" 
                                :rowaction="true" :rowdelete="true" :selectionMode="'single'" 
                                :dataKey="'number'" @deleted="deleteItem" :rows="entities" 
                                :rowedit="true" @edited="editItem" wrapperClass="field col-12 sm:col-5 md:col-12 xl:col-12"
                                :key="componentKey"/>
                        </div>
                    </div>
                </Fieldset>
            </div>
        </div>
    </div>
</template>
<script>
import FormInputText from "../../../components/general/FormInputText.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormDropdownComplex from "../../../components/general/FormDropdownComplex.vue";
// import DeleteDialog from "../../../components/general/DeleteDialog.vue";
import FormInputNumber from "../../../components/general/FormInputNumber.vue";
import { Customer } from '../../../models/comercial/Customer';
import { CustomerPayment } from '../../../models/comercial/CustomerPayment';
import { BankAccount } from "../../../models/cxp/BankAccount";
import {
    HeaderGrid,
    Rule,
    validate,
    fillObject,
//     Toast,
    ErrorToast,
    satCatalogo,
} from "../../../utilities/General";

import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import FormCalendar from "../../../components/general/FormCalendar.vue";
import HelperDialog from '../../../components/general/HelperDialog.vue';
export default {
    mixins: [Session],
    props: {
        modal: null,
        id: null
    },
    components: { Loader,  FormInputText, BasicFormToolbar, BasicDatatable, HelperDialog, FormCalendar, FormDropdownComplex, FormInputNumber },
    data(){
        return{
            entity: new CustomerPayment(this.session),
            entities: [],
            customers: [],
            c_FormaPago: [],
            c_Moneda: [],
            bank_accounts: [],
            loading: false,
            componentKey: 0,
            Hpayments: [
                new HeaderGrid('Numero de Pago', 'id'), 
                new HeaderGrid('Numero de cliente', 'id_customer'),
                new HeaderGrid('Cliente', 'customer'),
                new HeaderGrid('RFC del Cliente', 'rfc_customer'),
                new HeaderGrid('Fecha del Deposito', 'date', {type: "date"}), 
                new HeaderGrid('Moneda', 'currency'), 
                new HeaderGrid('Tasa de Cambio', 'exchange_rate', { type: 'currency' }),
                new HeaderGrid('Saldo', 'balance', { type: 'currency' }),
                new HeaderGrid('Estatus', 'status', {type: 'component', component:'Tag'}),
                
            ],
            rules: 
            [new Rule({ name: 'payment_type' }),
            new Rule({ name: 'amount' }),
            new Rule({ name: 'currency' }),
            new Rule({ name: 'exchange_rate' })],
            validate: {
                valid: false,
                validations: {
                    payment_type: null,
                    amount: null,
                    currency: null,
                    exchange_rate: null,
                },
            },
            customersHeaders: [new HeaderGrid('Nombre', 'name'), new HeaderGrid('RFC', 'rfc')],
            customerHelper: {
                visible: false,
            },
        }
    },
    watch: {
        'entity.currency'(newValue) {
            if (newValue == 'MXN') {
                this.entity.exchange_rate = 1.0;
            }
        },
    },
    created (){

    },
    async mounted(){
        await this.refresh();
        this.c_FormaPago = await satCatalogo(6);
        this.c_Moneda = await satCatalogo(9);
    },
    methods: {
        editItem(payload){
            this.entity = fillObject(this.entity, payload);
            let dateformat = payload.date;
            this.entity.date = new Date(dateformat);
            let account = payload.id_beneficiary_account;
            this.entity.id_bank_account_destination = account;
            this.entity.customer_name = payload.customer;
            this.entity.customer_rfc = payload.rfc_customer;
        },
        async deleteItem(payload){
            try{
                await new CustomerPayment(this.session).delete(payload.id);
                this.$toast.add({
                        summary: 'Eliminacion',
                        detail: 'Informacion Eliminada con exito',
                    });
            }
            catch(error){
                this.$toast.add(new ErrorToast(error));
            }
            finally{
                this.entities=await new CustomerPayment(this.session).pagos();
                this.loading = false;
                this.componentKey += 1;
            }
        },
        async savepayment() {
            try {
                //* Validacion de form
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw 'Favor de validar los campos';
                }
                if(this.entity.id != null && this.entity.id > 0 && this.entity.id != ''){
                    this.entity.status = 1;
                    await this.entity.update();
                }
                else{
                    this.entity.status = 1;
                    await this.entity.save();
                    this.$toast.add({
                        severity: "success",
                        summary: 'Creacion',
                        detail: 'Informacion guardada con exito',
                        life: 3000,
                    });
                }
                if(this.entities != [] && this.entities != '' && this.entities != null){
                    this.componentKey += 1;
                }
                this.entity = new CustomerPayment(this.session);
                this.newDialog = false;
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
                if(this.entities != [] && this.entities != '' && this.entities != null){
                    this.componentKey += 1;
                }
            }
            if(this.entities != [] && this.entities != '' && this.entities != null){
                this.entities=await new CustomerPayment(this.session).pagos();
                this.componentKey += 1;
            }
        },
        async openTable(){
            this.entities = await new CustomerPayment(this.session).pagos();
        },
        selectCustomer(customer){
            this.entity.id_customer = customer.id;
            this.entity.customer_name = customer.name;
            this.entity.customer_rfc = customer.rfc;
        },
        openNew() {
            this.entity = new CustomerPayment(this.session);
        },
        async save() {
        },
        async refresh() {
            this.loading = true;
            try {
                this.customers = await new Customer(this.session).all();
                this.entity = await new CustomerPayment(this.session);
                this.bank_accounts = await new BankAccount(this.session).all();
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            }
            finally{
                this.loading = false;
            }
        },
    }
};
</script>

<style>
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>